import React, { Component } from 'react';
import './Admin.css';

class Admin extends Component {
  render() {
    return (
      <div className="AdminWrapper">
        <h1>Admin</h1>
        <div className="AdminContent">
          <h2>Admin interface coming soon.</h2>
        </div>
      </div>
    );
  }
}

export default Admin;
